.game {
  font-family: sans-serif;
  display: grid;
  grid-template-rows: repeat(3, 100px);
  grid-template-columns: repeat(3, 100px);
  place-items: center;
}

.cell {
  width: 100%;
  height: 100%;
  font-size: 2rem;
  line-height: 100px;
  display: flex;
  justify-content: center;
}

.br {
  border-right: solid black 10px;
}
.bl {
  border-left: solid black 10px;
}

.bt {
  border-top: 10px solid #000;
}
.bb {
  border-bottom: solid black 10px;
}
